<template>
	<div class="search-container">
		<div class="search-top-img">
			<div class="search-input">
				<el-input v-model.trim="conPO.searchInfo" placeholder="请输入搜索内容" maxlength="200" @keyup.enter.native="handleQuery" @focus="focusInput" @change="changeSearch"></el-input>
				<span class="iconfont icon-sousuo" @click="handleQuery"></span>
				<div class="search-pull animated fadeIn" v-show="openPull && (hotSearch.length != 0 || historySearch.length != 0)" @mouseenter="enterPull" @mouseleave="leavePull">
					<div class="hot-box" v-show="hotSearch.length != 0">
						<div class="hot-name">热门搜索</div>
						<div class="hot-item-box">
							<div class="hot-item" v-for="(item, i) in hotSearch" :key="i" @click="selectTag(item.keyWord)">
								{{ item.keyWord }}
							</div>
						</div>
					</div>
					<div class="line" v-show="historySearch.length != 0"></div>
					<div class="history-box" v-show="historySearch.length != 0">
						<div class="history-name">
							<div>搜索历史</div>
							<div class="delete-box" @click="deleteHistory()">
								<div class="delete-icon"></div>
								清空历史
							</div>
						</div>
						<div class="history-item-box">
							<div class="history-item" v-for="(item, i) in historySearch" :key="i" @click="selectTag(item.historyInfo)">
								<div class="item-name">
									{{ item.historyInfo }}
								</div>
								<i class="el-icon-close" @click.stop="deleteHistory(item.historyId)"></i>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="tab-box" :class="activeName == 'industry' ? 'tab-box-industry' : ''">
				<el-tabs v-model="activeName" @tab-click="handleClick">
					<el-tab-pane label="拟建信息" name="4"></el-tab-pane> 
					<el-tab-pane label="土地交易" name="8"></el-tab-pane> 
					<el-tab-pane label="招标信息" name="5"></el-tab-pane>
					<el-tab-pane label="中标信息" name="6"></el-tab-pane>
					<el-tab-pane label="客户信息" name="7"></el-tab-pane>
					<el-tab-pane label="政策规划" name="3"></el-tab-pane>
				</el-tabs>
			</div>
		</div>
		<div class="result-box">
			<div class="empty-box" v-show="total == 0">
				<empty />
			</div>
			<div class="tab-list" v-show="activeName == 3">
				<div class="list-item" v-for="(item, index) in pagePolicy" :key="index" @click="toDetail(3, item)">
					<div class="name-top">
						<el-tooltip effect="light" :content="item.policyTitle" placement="bottom-start">
							<div class="text-ellipsis">
								{{ item.policyTitle }}
							</div>
						</el-tooltip>
					</div>
					<div class="bottom-box">
						<div class="tag-bottom">
							<div class="tag-item" v-for="(key, i) in item.policyTag" :key="i">
								{{ key }}
							</div>
						</div>
						<div class="date">{{ item.dispatchDate }}</div>
					</div>
				</div>
			</div>
			<div class="tab-list" v-show="activeName == 4">
				<div class="list-item" v-for="(item, index) in pageProposed" :key="index" @click="toDetail(4, item)">
					<div class="name-top">
						<el-tooltip effect="light" :content="item.projectName" placement="bottom-start">
							<div v-html="item.projectNameHighLight || item.projectName" class="text-ellipsis"></div>
						</el-tooltip>
					</div>
					<div class="bottom-box">
						<div class="tag-bottom">
							<div class="tag-item">项目阶段：{{ (item.stageCode == "立项审批项目" ? item.childStageCode : item.stageCode) || "-" }}</div>
							<div class="tag-item">地址：{{ item.city }}</div>
						</div>
						<div class="date">发布时间：{{ item.publishedDate }}</div>
					</div>
					<div class="bottom-content-box" v-show="item.contentsHighLight">
						<span class="contentTitle">内容：</span>
						<span v-html="item.contentsHighLight"></span>
					</div>
				</div>
			</div>
			<div class="tab-list" v-show="activeName == 5">
				<div class="list-bidding" v-for="(item, index) in pageBidding" :key="index" @click="toDetail(5, item)">
					<div class="name-top">
						<el-tooltip effect="light" :content="item.projectName" placement="bottom-start">
							<div v-html="item.projectNameHighLight || item.projectName" class="text-ellipsis"></div>
						</el-tooltip>
					</div>
					<div class="bottom-box">
						<div class="tag-bottom">
							<div class="tag-item">
								招标单位：
								<span v-html="item.tendereeHighLight || item.tenderee || '-'"></span>
							</div>
							<div class="tag-item">地址：{{ item.city }}</div>
						</div>
						<div class="date">发布时间：{{ item.publishedDate }}</div>
					</div>
					<div class="bottom-content-box" v-show="item.contentsHighLight">
						<span class="contentTitle">内容：</span>
						<span v-html="item.contentsHighLight"></span>
					</div>
				</div>
			</div>
			<div class="tab-list" v-show="activeName == 6">
				<div class="list-bidding" v-for="(item, index) in pageSuccessBidding" :key="index" @click="toDetail(6, item)">
					<div class="name-top">
						<el-tooltip effect="light" :content="item.projectName" placement="bottom-start">
							<div v-html="item.projectNameHighLight || item.projectName" class="text-ellipsis"></div>
						</el-tooltip>
					</div>
					<div class="bottom-box">
						<div class="tag-bottom">
							<div class="tag-item">
								中标单位：
								<span v-html="item.bidWinnerHighLight || item.bidWinner || '-'"></span>
							</div>
							<div class="tag-item">地址：{{ item.city }}</div>
						</div>
						<div class="date">发布时间：{{ item.publishedDate }}</div>
					</div>
					<div class="bottom-content-box" v-show="item.contentsHighLight">
						<span class="contentTitle">内容：</span>
						<span v-html="item.contentsHighLight"></span>
					</div>
				</div>
			</div>
			<div class="tab-list" v-show="activeName == 7">
				<div class="list-client" v-for="(item, index) in pageClient" :key="index" @click="toDetail(7, item)">
					<img class="img-left" v-if="item.logoUrl" @dragstart.prevent :src="item.logoUrl" alt="" />
					<div class="img-left-logo" v-else style="background-color: #6daae2">
						<div class="logo-img">{{ item.logoImg }}</div>
					</div>
					<div class="left-right">
						<div class="name-top">
							<el-tooltip effect="light" :content="item.clientName" placement="bottom-start">
								<div class="text-ellipsis">
									{{ item.clientName }}
								</div>
							</el-tooltip>
						</div>
						<div class="bottom-box" style="margin-bottom: 10px">
							<div class="tag-bottom">
								<div class="tag-item">企业法人：{{ item.legalPerson || "-" }}</div>
								<div class="tag-item">注册资本：{{ item.registeredCapital || "-" }}</div>
								<div class="tag-item">成立日期：{{ item.establishDate || "-" }}</div>
								<div class="tag-item">联系电话：{{ item.phone || "-" }}</div>
							</div>
						</div>
						<div class="bottom-box">
							<div class="tag-bottom">
								<div class="tag-item">
									企业地址：
									<el-tooltip effect="light" :content="item.address" placement="bottom-start" v-if="item.address">
										<span class="address text-ellipsis">{{ item.address }}</span>
									</el-tooltip>
									<span v-else>-</span>
								</div>
								<div class="tag-item">
									企业官网：
									<el-tooltip effect="light" :content="item.websiteLink" placement="bottom-start" v-if="item.websiteLink">
										<span class="link-item text-ellipsis" @click.stop="toLink(item.websiteLink)">{{ item.websiteLink }}</span>
									</el-tooltip>
									<span class="link" v-else>-</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			
			<div class="tab-list" v-show="activeName == 8">
				<div class="list-item" v-for="(item, index) in pagelan" :key="index" @click="toDetail(8,item)">
					<div class="name-top">
						<el-tooltip effect="light" :content="item.projectName" placement="bottom-start">
							<div v-html="item.projectName" class="text-ellipsis"></div>
						</el-tooltip>
						<collect v-model="item.isCollection" :itemId="item.id" type="土地交易"></collect>
					</div>
					<div class="bottom-box">
						<div class="tag-item flex-1">行政区划：{{ item.region || '-' }}</div>
						<div class="tag-item flex-1">土地使用权人：{{ item.landUseRight || '-' }}</div>
						<div class="tag-item flex-1">合同签订日期：{{ item.signingDate || '-' }}</div>
					</div>
					<div class="bottom-box">
						<div class="tag-item flex-1">土地用途：{{ item.landUse || '-' }}</div>
						<div class="tag-item flex-1">出让面积：{{ (item.landArea + '㎡' || '-') }}</div>
						<div class="tag-item flex-1">计划日期：{{ item.commencementDate || '-' }}</div>
					</div>
					<div class="bottom-content-box" v-show="item.contentsHighLight">
						<span class="contentTitle">内容：</span>
						<span v-html="item.contentsHighLight"></span>
					</div>
				</div> 
			</div>  
		</div>
		<div class="pagination-box">
			<pagination ref="pagination" :total="total" @change="paginChange" v-show="total != 0" :key="paginationKey"></pagination>
		</div>
		<login-modal ref="loginModal"></login-modal>
		<member-modal ref="memberModal"></member-modal>
		<ToPayOrMember ref="toPayOrMember" :showCommonText="false" :showPayBtn="showPayBtn" :text="text"></ToPayOrMember>
	</div>
</template>

<script>
import Pagination from "@/components/Pagination";
import Empty from "@/components/Empty";
import LoginModal from "@/components/loginModal";
import MemberModal from "@/components/MemberModal";
import ToPayOrMember from "@/components/ToPayOrMember";
export default {
	components: {
		Pagination,
		Empty,
		LoginModal,
		MemberModal,
		ToPayOrMember,
	},
	data() {
		return {
			text: "",
			showPayBtn: false,
			openPull: false, // 搜索下拉框是否展开
			hotSearch: [], // 热门搜索数据
			historySearch: [], // 历史搜索数据
			conPO: {
				platform: 1,
				searchInfo: "4",
				pageSize: 10, //页数
				pageNum: 1, // 页码
				type: 4, // 1 专题报告  2 建筑行业动态  3 政策规划 4 拟建信息 5 招标信息 6 中标信息、7 客户信息
				consumerId: this.$session.getUsers() ? this.$session.getUsers().consumerId : "",
			},
			activeName: "4",
			total: 0,
			pageResearch: [], // 专题报告数据
			pageIndustry: [], // 建筑行业动态数据
			pagePolicy: [], // 政策规划数据
			pageProposed: [], // 拟建信息数据
			pageBidding: [], // 招标信息数据
			pageClient: [], // 客户信息数据
			pageSuccessBidding: [], // 中标信息数据
			pagelan: [], // 土地交易数据
			paginationKey: "",
		};
	},
	created() {
		let search = this.$route.params.data;
		if (search) {
			this.conPO.searchInfo = search;
			localStorage.setItem("search", search);
		} else {
			this.conPO.searchInfo = localStorage.getItem("search");
		}
		let active = localStorage.getItem("active");
		if (active) {
			this.activeName = active;
			this.conPO.type = active;
		}
		this.search();
		this.searchKeyWord();
		this.searchHistory();
	},
	mounted() {
		// 监听click事件
		window.addEventListener("click", this.eventListener);
	},
	methods: {
		// 获取热门搜索
		searchKeyWord() {
			this.$api.home
				.searchKeyWord()
				.then(res => {
					this.hotSearch = res.data;
				})
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				});
		},
		// 获取搜索历史
		searchHistory() {
			if (this.$session.getUsers()) {
				this.$api.home
					.searchHistory({limit:10})
					.then(res => {
						this.historySearch = res.data;
					})
					.catch(msg => {
						if (msg?.msg) {
							this.$message.error(msg?.msg);
						}
					});
			}
		},
		// 清除历史记录
		deleteHistory(id) {
			let historyIds = "";
			if (id) {
				// 单条删除
				historyIds = id;
			} else {
				// 全部删除
				let {historySearch} = this;
				let ids = [];
				historySearch.forEach(item => {
					ids.push(item.historyId);
				});
				historyIds = ids.toString();
			}
			this.$api.home
				.deleteHistory(historyIds)
				.then(() => {
					if (id) {
						this.$message.success("已删除");
					} else {
						this.$message.success("已清空");
					}
					this.searchHistory();
				})
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				});
		},
		// 鼠标移入下拉
		enterPull() {
			// 移除监听click事件
			window.removeEventListener("click", this.eventListener);
		},
		// 鼠标移除下拉
		leavePull() {
			// 监听click事件
			window.addEventListener("click", this.eventListener);
		},
		// 点击搜索
		handleQuery() {
			this.openPull = false;
			localStorage.setItem("search", this.conPO.searchInfo);
			this.conPO.pageNum = 1;
			this.conPO.pageSize = 10;
			this.search();
			this.searchHistory();
		},
		// 调用搜索接口进行搜索
		search() {
			let {conPO} = this;
			const loading = this.$loading({
				lock: true,
				text: "加载中...",
				spinner: "el-icon-loading",
				background: "rgba(255, 255, 255, 0.3)",
				fullscreen: false,
			});
			let contentsHighLightReg = /[\u4e00-\u9fa5|\u3002|\uff1f|\uff01|\uff0c|\u3001|\uff1b|\uff1a|\u201c|\u201d|\u2018|\u2019|\uff08|\uff09|\u300a|\u300b|\u3008|\u3009|\u3010|\u3011|\u300e|\u300f|\u300c|\u300d|\ufe43|\ufe44|\u3014|\u3015|\u2026|\u2014|\uff5e|\ufe4f|\uffe5]+|<em>[^<]*<\/em>/g;
			this.$api.home
				.search(conPO)
				.then(res => {
					// 1 专题报告  2 建筑行业动态  3 政策规划 4 拟建信息 5 招标信息 6 中标信息、7 客户信息,8土地交易
					if (conPO.type == 1) {
						this.pageResearch = res.rows;
					} else if (conPO.type == 2) {
						res.rows.forEach(item => {
							item.shelvesDate = item.shelvesDate.slice(0, 10);
						});
						this.pageIndustry = res.rows;
					} else if (conPO.type == 3) {
						res.rows.forEach(item => {
							if (item.dispatchDate) {
								item.dispatchDate = item.dispatchDate.slice(0, 10);
							}
							if (item.policyTag) {
								item.policyTag = item.policyTag.split(",");
							}
						});
						this.pagePolicy = res.rows;
					} else if (conPO.type == 4) {
						res.rows.forEach(item => {
							item.publishedDate = item.publishedDate.toString().slice(0, 10);
							item.contentsHighLight = item.contentsHighLight.match(contentsHighLightReg).join("");
						});
						this.pageProposed = res.rows;
					} else if (conPO.type == 5) {
						res.rows.forEach(item => {
							item.publishedDate = item.publishedDate.slice(0, 10);
							item.contentsHighLight = item.contentsHighLight.match(contentsHighLightReg).join("");
						});
						this.pageBidding = res.rows;
					} else if (conPO.type == 6) {
						res.rows.forEach(item => {
							item.contentsHighLight = item.contentsHighLight.match(contentsHighLightReg).join("");
						});
						this.pageSuccessBidding = res.rows;
					} else if (conPO.type == 7) {
						res.rows.forEach(item => {
							if (!item.logoUrl) {
								this.$api.industry
									.getCompanyName({
										companyName: item.clientName,
									})
									.then(ress => {
										item.logoImg = ress.msg;
										this.$forceUpdate();
									})
									.catch(msg => {
										if (msg?.msg) {
											this.$message.error(msg?.msg);
										}
									});
							}
						});
						this.pageClient = res.rows;
					}else if (conPO.type == 8) {
						res.rows.forEach(item => { 
							item.contentsHighLight = item.contentsHighLight.match(contentsHighLightReg).join("");
						});
						this.pagelan = res.rows;
					}
					this.total = res.total;
				})
				.catch(msg => {
					console.log(2);
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				})
				.finally(() => {
					loading.close();
				});
		},
		// 点击tab切换
		handleClick(tab) {
			localStorage.setItem("active", tab.name);
			this.conPO.type = tab.name;
			this.paginationKey = new Date().getTime();
			this.conPO.pageNum = 1;
			this.conPO.pageSize = 10;
			this.search();
		},
		// 搜索获得焦点时
		focusInput() {
			window.removeEventListener("click", this.eventListener);
			this.openPull = true;
		},
		// 关闭下拉
		eventListener() {
			this.openPull = false;
		},
		// 搜索内容发生改变时
		changeSearch() {
			localStorage.setItem("search", this.conPO.searchInfo);
		},
		// 选择热门搜素或者历史搜索
		selectTag(name) {
			localStorage.setItem("search", name);
			this.conPO.searchInfo = name;
			this.openPull = false;
			this.conPO.pageNum = 1;
			this.conPO.pageSize = 10;
			this.search();
			this.searchHistory();
		},
		toDetail(type, item) {
			// 专题报告
			if (type == 1) {
				// 判断用户是否登录
				if (this.$session.getUsers()) {
					// 判断阅读，下载及会员情况
					this.$api.research
						.getReadAndDownload({
							reportId: item.reportId,
						})
						.then(res => {
							let data = res.data;
							if (data.read == "10011001") {
								// 有阅读权限
								let url = this.$router.resolve({
									path: `/research/market-detail?type=1&id=${item.reportId}`,
								});
								window.open(url.href, "_blank");
							} else {
								// 没有阅读权限
								let url = this.$router.resolve({
									path: `/research/market-preview?type=1&id=${item.reportId}`,
								});
								window.open(url.href, "_blank");
							}
						})
						.catch(msg => {
							if (msg?.msg) {
								this.$message.error(msg?.msg);
							}
						});
				} else {
					this.$refs.loginModal.visible = true;
				}
			} else if (type == 2) {
				// 建筑行业动态
				if (item.url) {
					this.$confirm("您即将跳转进入第三方网站，慧决策平台不对该网站内容及真实性负责，请注意您的账号和财产安全", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					}).then(() => {
						window.open(item.url, "_blank");
					});
				} else {
					// 判断用户是否登录
					let url = this.$router.resolve({
						path: `/industry/industry-detail?type=1&id=${item.industryDynamicId}`,
					});
					if (this.$session.getUsers()) {
						window.open(url.href, "_blank");
					} else {
						this.$refs.loginModal.visible = true;
					}
				}
			} else if (type == 3) {
				this.$api.industry
					.selectPolicyPlanUrlById(item.policyPlanId)
					.then(res => {
						if (!res.data) {
							// 判断用户是否登录
							let url = this.$router.resolve({
								path: `/industry/industry-detail?type=2&id=${item.policyPlanId}`,
							});
							if (this.$session.getUsers()) {
								window.open(url.href, "_blank");
							} else {
								this.$refs.loginModal.visible = true;
							}
						} else {
							this.$confirm("您即将跳转进入第三方网站，慧决策平台不对该网站内容及真实性负责，请注意您的账号和财产安全", {
								confirmButtonText: "确定",
								cancelButtonText: "取消",
								type: "warning",
							}).then(() => {
								window.open(res.data.url, "_blank");
							});
						}
					})
					.catch(msg => {
						if (msg.code == "10001111") {
							this.$refs.toPayOrMember.openDialog();
							this.text = msg?.msg;
						} else {
							this.$message.error(msg?.msg);
						}
					});
			} else if (type == 4) {
				// 拟建信息
				const routeUrl = this.$router.resolve({
					path: `/industry/proposed-detail?id=${item.proposedProjectId}`,
				});
				// 判断用户是否登录
				if (this.$session.getUsers()) {
					window.open(routeUrl.href, "_blank");
				} else {
					this.$refs.loginModal.visible = true;
				}
			} else if (type == 5) {
				// 招标信息
				let url = this.$router.resolve({
					path: `/industry/bidding-detail?id=${item.tenderId}`,
				});
				// 判断用户是否登录
				if (this.$session.getUsers()) {
					window.open(url.href, "_blank");
				} else {
					this.$refs.loginModal.visible = true;
				}
			} else if (type == 6) {
				// 中标信息
				let url = this.$router.resolve({
					path: `/project/bidding-project-detail?id=${item.id}`,
				});
				// 判断用户是否登录
				if (this.$session.getUsers()) {
					window.open(url.href, "_blank");
				} else {
					this.$refs.loginModal.visible = true;
				}
			} else if (type == 7) {
				// 判断用户是否登录
				if (this.$session.getUsers()) {
					let type = item.clientType == "60661001" ? 1 : 2;
					this.$api.industry
						.selectClientInfo({clientId: item.clientId})
						.then(() => {
							this.$api.personalCenter
								.isMyClient({clientId: item.clientId})
								.then(res => {
									// if (res.data == 0) {
									// 	let url = this.$router.resolve({
									// 		path: `/client/client-detail?id=${item.clientId}&type=${type}`,
									// 	});
									// 	window.open(url.href, "_blank");
									// } else {
									let url = this.$router.resolve({
										path: `/client/consumer-detail?id=${item.clientId}&type=${type}&pathType=2`,
									});
									window.open(url.href, "_blank");
									// }
								})
								.catch(msg => {
									if (msg?.msg) {
										this.$message.error(msg?.msg);
									}
								});
						})
						.catch(msg => {
							if (msg?.msg) {
								this.$message.error(msg?.msg);
							}
						});
				} else {
					this.$refs.loginModal.visible = true;
				}
			}else if (type == 8) {
				// 土地交易信息
				const routeUrl = this.$router.resolve({
					path: `/project/land-detail?id=${item.id}`,
				});
				// 判断用户是否登录
				if (this.$session.getUsers()) {
					window.open(routeUrl.href, "_blank");
				} else {
					this.$refs.loginModal.visible = true;
				}
			}
		},
		// 点击跳转公司官网
		toLink(url) {
			if (!url) {
				return;
			}
			this.$confirm("您即将跳转进入第三方网站，慧决策平台不对该网站内容及真实性负责，请注意您的账号和财产安全", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			}).then(() => {
				window.open(url, "_blank");
			});
		},
		// 分页方法
		paginChange(page, pageSize) {
			this.conPO.pageNum = page;
			this.conPO.pageSize = pageSize;
			this.search();
		},
	},
	destroyed() {
		// 移除监听click事件
		window.removeEventListener("click", this.eventListener);
	},
};
</script>

<style lang="less" scoped>
@import "./index.less";
</style>
